import './src/styles/global.css';

import { Script, type GatsbyBrowser } from 'gatsby';
import { PostHogProvider } from 'posthog-js/react';
import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { persistQueryClient } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { broadcastQueryClient } from '@tanstack/query-broadcast-client-experimental';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { isSSR } from './src/utils';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        const retry = [429, 504].includes((error as any)?.cause) && failureCount < 6;
        console.log(`retry ${retry}`);
        return retry;
      },
      retryDelay: (attemptIndex) => {
        const gap = 1000 * 2 ** (1 + Math.min(attemptIndex, 3));
        const delay = gap + Math.floor(Math.random() * gap);
        console.log(`retry# ${attemptIndex}: ${delay}`);
        return delay;
      },
    },
  },
});

if (typeof window !== 'undefined') {
  const localStoragePersistor = createSyncStoragePersister({
    storage: window.localStorage,
  });

  persistQueryClient({
    queryClient,
    persister: localStoragePersistor,
    dehydrateOptions: {
      shouldDehydrateQuery: ({ queryKey }) => {
        if (!queryKey.length) {
          return true;
        }

        if ((queryKey[0] as string).startsWith('/cardcatalog/connectorstatus:')) {
          return false;
        }

        return true;
      },
    },
  });
}

broadcastQueryClient({
  queryClient,
  broadcastChannel: 'adenin-digital-assistant',
});

if (typeof document !== 'undefined' && typeof navigator !== 'undefined' && /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)) {
  document.onreadystatechange = () => {
    if (document.readyState !== 'complete') return;
    document.documentElement.classList.add('platform-apple');
  };
}

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  if (!sessionStorage.length) {
    console.log('Ask other tabs for session storage');
    localStorage.setItem('getSessionStorage', Date.now().toString());
  }

  window.addEventListener('storage', (event) => {
    if (event.key === 'getSessionStorage') {
      console.log('Some tab asked for the sessionStorage -> send it');

      localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
      localStorage.removeItem('sessionStorage');
    }

    if (event.key === 'sessionStorage' && !sessionStorage.length) {
      console.log('sessionStorage is empty -> fill it');

      if (!event.newValue) {
        return;
      }

      const data = JSON.parse(event.newValue);

      Object.keys(data).forEach((key) => {
        sessionStorage.setItem(key, data[key]);
      });
    }
  });

  if (process.env.NODE_ENV !== 'production') {
    import('@welldone-software/why-did-you-render').then((wdyr) => {
      wdyr.default(React, {
        trackAllPureComponents: true,
        exclude: [
          /^RouterImpl/,
        ],
      });
    });
  }
};

const posthogOptions = {
  api_host: 'https://eu.posthog.com',
  opt_in_site_apps: true,
  session_recording: {
    maskAllInputs: false,
    maskInputOptions: {
      password: true,
    },
  },
};

const MaybePostHogProvider = isSSR ? ({ children }: { children: JSX.Element }) => children : PostHogProvider;

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => (
  <MaybePostHogProvider
    apiKey="phc_1mEwwsp16wREnGrf1UU67YmhTOmLioNpEyaYKQzRnUi"
    options={posthogOptions}
  >
    <QueryClientProvider client={queryClient}>
      {element}
      <ReactQueryDevtools initialIsOpen={false} />
      <Script src="/iframeResizer.contentWindow.min.js" />
    </QueryClientProvider>
  </MaybePostHogProvider>
);
